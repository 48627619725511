import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import scaleSettingsService from "services/ScaleSettingsService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  scaleSettings: {},
  status: false,
};

export const getScaleSettings = createAsyncThunk(
  "get-scale-settings",
  async (data, { rejectWithValue }) => {
    try {
      const response = await scaleSettingsService.getScaleSettings(data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateScaleSettings = createAsyncThunk(
  "update-scale-settings",
  async (data, { rejectWithValue }) => {
    try {
      const response = await scaleSettingsService.updateScaleSettings(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const scaleSettingsSlice = createSlice({
  name: "scaleSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScaleSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScaleSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.scaleSettings = action.payload;
      })
      .addCase(getScaleSettings.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(updateScaleSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateScaleSettings.fulfilled, (state, action) => {
        state.loading = false;
        notification.success({
          message: action.payload.message,
        });
      })
      .addCase(updateScaleSettings.rejected, (state, action) => {
        state.loading = false;
        notification.error({
          message: action.payload,
        });
      });
  },
});

export const {} = scaleSettingsSlice.actions;

export default scaleSettingsSlice.reducer;
