import { combineReducers } from 'redux'
import auth from './slices/authSlice'
import device from './slices/deviceSlice'
import scaleLogs from './slices/scaleLogsSlice'
import scaleSettings from './slices/scaleSettingsSlice'
import theme from './slices/themeSlice'
import user from './slices/userSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        user,
        scaleSettings,
        scaleLogs,
        device,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
