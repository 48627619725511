import fetch from 'auth/FetchInterceptor'

const scaleSettingsService = {}

scaleSettingsService.getScaleSettings = function (params) {
  return fetch({
    url: '/scale-settings',
    method: 'get',
    params
  })
}

scaleSettingsService.updateScaleSettings = function (data) {
  return fetch({
    url: '/scale-settings',
    method: 'post',
    data
  })
}

export default scaleSettingsService