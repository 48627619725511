import fetch from 'auth/FetchInterceptor'

const userService = {}

userService.getUserProfile = function (params) {
  return fetch({
    url: '/user',
    method: 'get',
    params
  })
}

userService.getAllUsers = function (params) {
  return fetch({
    url: '/user/all',
    method: 'get',
    params
  })
}

export default userService