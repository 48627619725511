import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import AppRoute from "./AppRoute";
import ProtectedRoleRoutes from "./ProtectedRoleRoutes";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import {
  ContactPage,
  ForgotPasswordComponent,
  LandingPage,
  LoginComponent,
  RegisterComponent,
} from "./components";

const Routes = () => {
  const user = useSelector((state) => state.user.user);

  return (
    <RouterRoutes>
      <Route path="/" element={<PublicRoute />}>
        <Route
          key="landing"
          path="/"
          element={<AppRoute routeKey={"landing"} component={LandingPage} />}
        />
        <Route
          key="contactUs"
          path="/contact"
          element={<AppRoute routeKey={"contactUs"} component={ContactPage} />}
        />
        <Route
          key="login"
          path={`${AUTH_PREFIX_PATH}/login`}
          element={<AppRoute routeKey={"login"} component={LoginComponent} />}
        />
        <Route
          key="register"
          path={`${AUTH_PREFIX_PATH}/register`}
          element={
            <AppRoute routeKey={"register"} component={RegisterComponent} />
          }
        />
        <Route
          key="forgot-password"
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          element={
            <AppRoute
              routeKey={"forgot-password"}
              component={ForgotPasswordComponent}
            />
          }
        />
      </Route>

      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<ProtectedRoleRoutes path="/" user={user} />}
        />
        <Route
          key={"dashboard.search"}
          path={`${APP_PREFIX_PATH}/dashboards/home`}
          element={
            <ProtectedRoleRoutes
              path={`${APP_PREFIX_PATH}/dashboards/home`}
              user={user}
            />
          }
        />
        <Route
          key={"home.Home"}
          path={`${APP_PREFIX_PATH}/dashboards/devices`}
          element={
            <ProtectedRoleRoutes
              path={`${APP_PREFIX_PATH}/dashboards/devices`}
              user={user}
            />
          }
        />
        <Route
          key={"home.Home"}
          path={`${APP_PREFIX_PATH}/dashboards/settings`}
          element={
            <ProtectedRoleRoutes
              path={`${APP_PREFIX_PATH}/dashboards/settings`}
              user={user}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
