import fetch from 'auth/FetchInterceptor'

const deviceService = {}

deviceService.getAllDevices = function (params) {
    return fetch({
      url: '/device',
      method: 'get',
      params
    })
  }

deviceService.addDevice = function (data) {
  return fetch({
    url: '/device',
    method: 'post',
    data
  })
}

deviceService.updateDevice = function (data) {
  return fetch({
    url: '/device',
    method: 'put',
    data
  })
}

deviceService.deleteDevice = function (param) {
  return fetch({
    url: `/device/${param._id}`,
    method: 'delete',
    param
  })
}

export default deviceService