import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import scaleLogsService from "services/ScaleLogsService";

export const initialState = {
  rawDataLoading: false,
  logsLoading: false,
  message: "",
  showMessage: false,
  avgLoadedTruckWeight: 0,
  loadedTruckCount: 0,
  emptyTruckCount: 0,
  graphXAxisLoadedTrucks: [],
  graphYAxisLoadedTrucks: [],
  status: false,
  totalRawDataCount: 0,
  graphXAxisRawTimeStamp: [],
  graphYAxisRawTruckWeights: [],
  allRawLogs: [],
  averageWeightCheckLoading: false,
};

export const getScaleLogs = createAsyncThunk(
  "get-scale-logs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await scaleLogsService.getScaleLogs(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getRawData = createAsyncThunk(
  "get-raw-data",
  async (data, { rejectWithValue }) => {
    try {
      const response = await scaleLogsService.getRawData(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateAverageWeightCheck = createAsyncThunk(
  "update-average-weight-check",
  async (data, { rejectWithValue }) => {
    try {
      const response = await scaleLogsService.updateAverageWeightCheck(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const scaleLogsSlice = createSlice({
  name: "scaleLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScaleLogs.pending, (state) => {
        state.logsLoading = true;
      })
      .addCase(getScaleLogs.fulfilled, (state, action) => {
        state.logsLoading = false;
        state.avgLoadedTruckWeight = action.payload.data.averageLoadedWeight
          ? action.payload.data.averageLoadedWeight
          : 0;
        state.loadedTruckCount = action.payload.data.loadedTruckCount
          ? action.payload.data.loadedTruckCount
          : 0;
        state.emptyTruckCount = action.payload.data.emptyTruckCount
          ? action.payload.data.emptyTruckCount
          : 0;
        state.graphXAxisLoadedTrucks = action.payload.data
          .graphXAxisLoadedTrucks
          ? action.payload.data.graphXAxisLoadedTrucks
          : [];
        state.graphYAxisLoadedTrucks = action.payload.data
          .graphYAxisLoadedTrucks
          ? action.payload.data.graphYAxisLoadedTrucks
          : [];
      })
      .addCase(getScaleLogs.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.logsLoading = false;
      })
      .addCase(getRawData.pending, (state) => {
        state.rawDataLoading = true;
      })
      .addCase(getRawData.fulfilled, (state, action) => {
        state.rawDataLoading = false;
        state.totalRawDataCount = action.payload.data.totalCount
          ? action.payload.data.totalCount
          : 0;
        state.avgLoadedTruckWeight = action.payload.data.averageWeight
          ? action.payload.data.averageWeight
          : 0;
        state.graphXAxisRawTimeStamp = action.payload.data.graphXAxisTimeStamp
          ? action.payload.data.graphXAxisTimeStamp
          : [];
        state.graphYAxisRawTruckWeights = action.payload.data
          .graphYAxisTruckWeights
          ? action.payload.data.graphYAxisTruckWeights
          : [];
        state.allRawLogs = action.payload.data.allLogs
          ? action.payload.data.allLogs
          : [];
      })
      .addCase(getRawData.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.rawDataLoading = false;
      })
      .addCase(updateAverageWeightCheck.pending, (state) => {
        state.averageWeightCheckLoading = true;
      })
      .addCase(updateAverageWeightCheck.fulfilled, (state, action) => {
        state.averageWeightCheckLoading = false;
        notification.success({
          message: "Data updated successfully!",
          placement: "topRight",
        });
      })
      .addCase(updateAverageWeightCheck.rejected, (state, action) => {
        state.averageWeightCheckLoading = false;
        notification.error({
          message: "Something went wrong! Please try again",
          placement: "topRight",
        });
      });
  },
});

export const {} = scaleLogsSlice.actions;

export default scaleLogsSlice.reducer;
