import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/UserService';

export const initialState = {
	isUsersLoading: false,
	message: '',
	showMessage: false,
	redirect: '',
	status:  false,
    user: {},
	users: [],
	localEmail: localStorage.getItem("user_email") || null
}

export const getUserProfile = createAsyncThunk('user',async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.getUserProfile(data)
		
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAllUsers = createAsyncThunk('get-all-users', async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.getAllUsers(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		userenticated: (state, action) => {
		},
		setUserLocalEmail: (state, action) => {
			localStorage.setItem("user_email", action.payload);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserProfile.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserProfile.fulfilled, (state, action) => {
				state.loading = false
				state.user = action.payload
			})
			.addCase(getUserProfile.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(getAllUsers.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllUsers.fulfilled, (state, action) => {
				state.loading = false
				state.users = action.payload.data
			})
			.addCase(getAllUsers.rejected, (state, action) => {
				console.log(action)
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	userenticated,
	setUserLocalEmail
} = userSlice.actions

export default userSlice.reducer