import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import deviceService from "services/DeviceService";

export const initialState = {
  devicesLoading: false,
  message: "",
  showMessage: false,
  devices: [],
  status: false,
};

export const getAllDevices = createAsyncThunk(
  "get-devices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deviceService.getAllDevices(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addDevice = createAsyncThunk(
  "add-device",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deviceService.addDevice(data);

      console.log(response.data)

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateDevice = createAsyncThunk(
  "update-device",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deviceService.updateDevice(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const deleteDevice = createAsyncThunk(
  "delete-device",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const response = await deviceService.deleteDevice(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDevices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDevices.fulfilled, (state, action) => {
        state.loading = false;
        state.devices = action.payload.data;
      })
      .addCase(getAllDevices.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(addDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDevice.fulfilled, (state, action) => {
        state.loading = false;
        notification.success({
          message: action.payload.message,
        });
      })
      .addCase(addDevice.rejected, (state, action) => {
        state.loading = false;
        console.log(action.payload)
        notification.error({
          message: action.payload,
        });
      })
      .addCase(updateDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDevice.fulfilled, (state, action) => {
        state.loading = false;
        notification.success({
          message: action.payload.message,
        });
      })
      .addCase(updateDevice.rejected, (state, action) => {
        state.loading = false;
        notification.error({
          message: action.payload,
        });
      })
      .addCase(deleteDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        state.loading = false;
        notification.success({
          message: action.payload.message,
        });
      })
      .addCase(deleteDevice.rejected, (state, action) => {
        state.loading = false;
        notification.error({
          message: action.payload,
        });
      });
  },
});

export const {} = deviceSlice.actions;

export default deviceSlice.reducer;
